body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header.top {
  text-align: 'center';
  color: #eee;
  background-color: #0f0f0f;

}
header.top h1 {
  color: #eee;
  display: flex;
  justify-content: 'center';
}
header.top h1 > a {
  color: #eee;
}
header.top h1 > a:hover {
  color: #007bff;
}
header.top a:hover {
  text-decoration: none;
}
header.top .tagline {
  font-size: 20px;
  padding-top: 14px;
}
header .nav-links {
  color: #fff;
  font-size: 20px;
  padding: 0px;
}
footer {
  padding-bottom: 20px;
  border-top: 1px solid #eee;
  /* background-image:
    linear-gradient(
   #ffffff, #ffffff, #eee
    ); */
}
footer .footer-alert {
  margin:20px auto;
  padding:0px 10px 5px;
  text-align: center;
}
.input-danger:focus {
  color: #dc3545!important; /* #495057; */
  background-color: #fff8f8 !important;
  border-color: #dc3545!important; /* #80bdff; */
  box-shadow: 0 0 0 0.2rem rgba(252, 214, 214, 0.25) !important;
}

.pagination li a {
  border-radius: 7px;
  padding: 0.1rem 1rem;
  border: gray 1px solid;
  cursor: pointer;
}

.pagination li.previous a,
.pagination li.next a,
.pagination li.break a {
  border-color: transparent;
}

.pagination li.active a {
  background-color: #0366d6;
  border-color: transparent;
  color: white;
  min-width: 32px;
}
.pagination li.disabled a {
  color: grey;
}

.pagination li.disable,
.pagination li.disabled a {
  cursor: default;
}

.visually-hidden {
  display: none;
}

.modal-title{
  width: 100%;
}

.color-primary{ color: #5956E6 }
.color-secondary{ color: #2ED46D }
.color-danger { color: #f53d3d }
.color-light{ color: #f4f4f4 }
.color-dark{ color: #222 }
.color-background{ color: #2D2D3B }
.color-pending{ color: #F58C3D }
.color-success{ color: #327eff }

.btn-custom-primary{ background-color: #5956E6!important; color: white!important; border-color: #5956E6!important; }
.btn-custom-secondary{ background-color: #2ED46D!important; color: white!important; border-color: #2ED46D!important; }
.btn-custom-danger { background-color: #f53d3d!important; color: white!important; border-color: #f53d3d!important; }
.btn-custom-light{ background-color: #6c757d!important; color: white!important; border-color: #6c757d!important; }
.btn-custom-dark{ background-color: #222!important; color: white!important; border-color: #222!important; }
.btn-custom-background{ background-color: #2D2D3B!important; color: white!important; border-color: #2D2D3B!important; }
.btn-custom-pending{ background-color: #F58C3D!important; color: white!important; border-color: #F58C3D!important; }
.btn-custom-success{ background-color: #327eff!important; color: white!important; border-color: #327eff!important; }

.needs_restock {
  background-color: #f53d3d;
}

.restocked {
  background-color: #2ED46D
}

.packed {
  background-color: #ededed;
  color: #a9a9a9;
}
